//ページTOP
$(function () {
  $(window).scroll(function () {
    var now = $(window).scrollTop();
    var under = $('body').height() - (now + $(window).height());
    if (now > 600) {
      // $('#page-top').fadeIn('slow');
      $('.header-fixed').addClass('scloll');
      $('.side-link').addClass('scloll');
    } else {
      // $('#page-top').fadeOut('slow');
      $('.header-fixed').removeClass('scloll');
      $('.side-link').removeClass('scloll');
    }

    if (now > 60) {
      $('#header').addClass('header-fixed');
    } else {
      $('#header').removeClass('header-fixed');
    }
    // console.log(now);
  });

  $('a[href^="#"]').click(function () {
    var speed = 500;
    var href = $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({
      scrollTop: position
    }, speed, "swing");
    return false;
  });

  // $('#move-page-top').click(function () {
  //   $('html,body').animate({
  //     scrollTop: 0
  //   }, 'slow');
  // });

  $('.drawer').drawer({
    iscroll: {
      scrollX: true,
      scrollY: true,
      click: true,
      mouseWheel: true,
      disablePointer: true,
      disableTouch: false,
      disableMouse: false
    },
  });
  $('.drawer-nav').on('click', function () {
    $('.drawer').drawer('close');
  });
  $('.drawer-nav ul li a').on('click', function () {
    $('.drawer').drawer('close');
  });

});
